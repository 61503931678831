import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/tailwind.css";

const app = createApp(App);

app.use(router);
app.mount("#app");

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("SW registered: ", registration);

        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          installingWorker.onstatechange = () => {
            if (installingWorker.state === "installed") {
              if (navigator.serviceWorker.controller) {
                // New update available
                console.log("New content is available; please refresh.");
                // Optionally, you can show a notification to the user to refresh the page
                if (confirm("New version available. Refresh now?")) {
                  window.location.reload();
                }
              } else {
                // Content is cached for offline use
                console.log("Content is cached for offline use.");
              }
            }
          };
        };

        if (registration.waiting) {
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  });

  // Set Workbox log level to silent
  if (window.workbox) {
    window.workbox.setConfig({ debug: false });
  }
}
