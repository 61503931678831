<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<style>
@font-face {
  font-family: "Speda-Bold";
  src: url("~@/assets/fonts/Speda-Bold.eot"); /* IE9 Compat Modes */
  src: url("~@/assets/fonts/Speda-Bold.eot?#iefix") format("embedded-opentype"),
    /* Super Modern Browsers */ url("~@/assets/fonts/Speda-Bold.woff")
      format("woff"),
    /* Modern Browsers */ url("~@/assets/fonts/Speda-Bold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("~@/assets/fonts/Speda-Bold.svg#Speda-Bold")
      format("svg"); /* Legacy iOS */
  font-weight: bold;
  font-style: normal;
}

.font_Speda-Bold {
  font-family: "Speda-Bold";
}
body {
  background-color: #f5f5f5;
  font-family: "Speda-Bold", sans-serif;
}
</style>
